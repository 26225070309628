import { isLogin as _isLogin } from "../utils/auth";
import axios from "../api/axios";
import { setUserInfo, getUserInfo } from "../utils/auth";
export default {
  state: {
    isLogin: false,
    userName: getUserInfo()
      ? getUserInfo().nickName ||
        getUserInfo().realName ||
        getUserInfo().organStudentSerial ||
        ""
      : "",
    account: {},
  },
  mutations: {
    checkIsLogin(state) {
      state.isLogin = _isLogin();
    },
    changeUserInfo(state, data) {
      if (data) {
        setUserInfo(data);
        state.userName =
          data.nickName ||
          data.realName ||
          data.organStudentSerial ||
          "";
      } else {
        axios.getUserInfoApi().then((res) => {
          const info = res.result.accountDetail || {};
          setUserInfo(info);
          state.userName =
            info.nickName ||
            info.realName ||
            info.organStudentSerial ||
            "";
        });
      }
    },
    changeAccount(state, data) {
      state.account = data;
    },
  },
};
