const trackTypeObj = {
  "pages/agent/home": "2001",
  "pages/agent/task-list": "2002",
  "pages/agent/task-detail": "2003",
  "pages/agent/withdraw": "2004",
  "pages/agent/customer": "2005",
  "pages/agent/rule": "2006",
  "pages/agent/buy": "2007",
  "pages/agent/check-code": "2008",
  "pages/agent/share": "2009",
  "pages/agent/code": "2010",
};

const categoryList = [
  {
    name: "IT互联网",
    sub: [],
  },
  {
    name: "设计",
    sub: [],
  },
  {
    name: "游戏",
    sub: [],
  },
  {
    name: "剪辑",
    sub: [],
  },
  {
    name: "证书培训",
    sub: [],
  },
  {
    name: "c端首页类目",
    sub: [
      {
        name: "就业直通",
      },
      {
        name: "兴趣培养",
      },
      {
        name: "技能提升",
      },
    ],
  },
  {
    name: "b端首页类目",
    sub: [
      {
        name: "电子竞技运动与管理",
      },
      {
        name: "数字媒体与技术",
      },
      {
        name: "视觉媒体设计",
      },
      {
        name: "职业素养提升",
      },
    ],
  },
];

const sexTypeList = [
  {
    text: "男",
    value: 1,
  },
  {
    text: "女",
    value: 2,
  },
  {
    text: "保密",
    value: 0,
  },
];

const courseTypeList = [
  {
    text: "录播",
    value: 1,
  },
  {
    text: "直播",
    value: 2,
  },
  {
    text: "实训",
    value: 3,
  },
  {
    text: "直播+录播",
    value: 4,
  },
];

const courseTypes = {
  1: "录播",
  2: "直播",
  3: "实训",
  4: "直播+录播",
};

const liveStatus = {
  0: "未开始",
  1: "进行中",
  2: "已结束",
  98: "已删除",
  99: "未创建",
};

const grades = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
};

const orderStatus = {
  1: "待付款",
  2: "支付中",
  3: "支付完成",
  4: "支付失败",
  5: "物流派送中",
  6: "订单已完成",
  97: "待确认",
  98: "订单超时",
  99: "已取消",
};

const couponAvailableObj = {
  0: "可使用",
  1: "不在可用时段",
  2: "已过期",
  3: "未达到金额条件",
  4: "该商品不可用",
  5: "不可用于此方式",
};

const takeOutObj = {
  0: "提现中",
  1: "待处理",
  2: "成功",
  3: "驳回",
};

export {
  categoryList,
  sexTypeList,
  courseTypeList,
  courseTypes,
  liveStatus,
  grades,
  orderStatus,
  couponAvailableObj,
  takeOutObj,
  trackTypeObj,
};
