<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getOpenId } from "./utils/auth";
export default {
  name: "app",
  created() {
    const w = document.documentElement.clientWidth / 750; //获取视口大小
    const styleNode = document.createElement("style");
    styleNode.innerHTML = "html{font-size:" + w + "px!important}"; //向head标签中添加style标签，其中包含html{font-size:w;}
    document.head.appendChild(styleNode);
  },
};
</script>

<style lang="less">
.empty {
  width: max-content;
  margin: 180px auto 0;
  text-align: center;
  img {
    margin-bottom: 15px;
    width: 115px;
    height: 105px;
  }
  .text {
    line-height: 1;
    font-size: 12px;
    color: #1a1a1a;
  }
}

@font-face {
  font-family: "iconfont"; /* Project id 3882133 */
  src: url('//at.alicdn.com/t/c/font_3882133_csl34dp9ac7.woff2?t=1701133870908') format('woff2'),
       url('//at.alicdn.com/t/c/font_3882133_csl34dp9ac7.woff?t=1701133870908') format('woff'),
       url('//at.alicdn.com/t/c/font_3882133_csl34dp9ac7.ttf?t=1701133870908') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-youjiantou:before {
  content: "\e629";
}

.icon-gouxuan-weixuanzhong-xianxingyuankuang:before {
  content: "\e77c";
}

.icon-gouxuan_mian:before {
  content: "\e630";
}
.icon-shouqi-copy:before {
  content: "\fbba";
}
.icon-lubobofang:before {
  content: "\e616";
}
.icon-fuzhi:before {
  content: "\e8b0";
}
.icon-sousuo1:before {
  content: "\e601";
}
</style>
