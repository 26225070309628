import { setUuid, onTrack } from "../utils/track";
export default {
  state: {},
  actions: {
    pushTrackData(context, params) {
      onTrack({
        type: params.type,
        behavior: params.behavior,
        params: params.params,
      });
    },
    changeUuid(context, params) {
      setUuid(params);
    },
  },
  mutations: {},
};
