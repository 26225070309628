const UserInfo = "userInfo";
const AccessToken = "accesstoken";
const OpenId = "OPENID";
const isLogin = () => {
  return !!localStorage.getItem(AccessToken);
}
const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(UserInfo));
};
const setUserInfo = (data) => {
  localStorage.setItem(UserInfo, JSON.stringify(data));
};
const clearUserInfo = () => {
  localStorage.removeItem(UserInfo);
};

const getToken = () => {
  return localStorage.getItem(AccessToken);
};
const setToken = (data) => {
  localStorage.setItem(AccessToken, data);
};
const clearToken = () => {
  localStorage.removeItem(AccessToken);
};

const getOpenId = () => {
  return localStorage.getItem(OpenId);
};
const setOpenId = (data) => {
  localStorage.setItem(OpenId, data);
};
const clearOpenId = () => {
  localStorage.removeItem(OpenId);
};

export {
  isLogin,
  getUserInfo,
  setUserInfo,
  clearUserInfo,
  getToken,
  setToken,
  clearToken,
  getOpenId,
  setOpenId,
  clearOpenId,
};