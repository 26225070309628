import { Get, Post, Put, Patch, Delete } from "./request.js";

export default {
  // login
  loginApi: (params) => {
    return Post("/api/login/", params);
  },
  logoutApi: (params) => {
    return Post("/api/logout/", params);
  },
  getLiveTokenApi: (params) => {
    return Post("/api/live/gettoken/", params);
  },
  sendLoginCodeApi: (params) => {
    return Post("/api/sms/send/login/", params);
  },
  getUserInfoApi: (params) => {
    return Post("/api/personal/detail/", params);
  },
  sendRegisterCodeApi: (params) => {
    return Post("/api/sms/send/register/", params);
  },
  onRegisterPhoneApi: (params) => {
    return Post("/api/register/create/", params);
  },
  onRegisterConfirmApi: (params) => {
    return Post("/api/register/confirm/", params);
  },
  verifyCodeApi: (params) => {
    return Post("/api/personal/password/resetbyphone_verify/", params);
  },
  forgetCodeByPhoneCodeApi: (params) => {
    return Post("/api/personal/password/resetbyphone/", params);
  },
  joinActiveApi: (params) => {
    return Post("/api/activity/join/", params);
  },
  // home
  getBannerListApi: (params) => {
    return Post("/api/banner/query/", params);
  },
  behaviorUploadApi: (params) => {
    return Post("/api/behavior/upload/", params);
  },
  startPlayGameApi: (params) => {
    return Post("/api/shandw/play/", params);
  },
  // 添加图片
  uploadImageApi: (params) => {
    return Post("/api/personal/addimagebyte/", params);
  },
  // myInfo
  changeUserInfoApi(params) {
    return Post("/api/personal/modify/", params);
  },
  // account
  sendBindPhoneCodeApi: (params) => {
    return Post("/api/sms/send/bindphone/", params);
  },
  bindPhoneApi: (params) => {
    return Post("/api/personal/bindphone/", params);
  },
  sendOldPhoneCodeApi: (params) => {
    return Post("/api/sms/send/modifyphone/", params);
  },
  sendNewPhoneCodeApi: (params) => {
    return Post("/api/sms/send/setnewphone/", params);
  },
  changePhoneStep1Api: (params) => {
    return Post("/api/personal/modifybind_verify/", params);
  },
  changePhoneStep2Api: (params) => {
    return Post("/api/personal/modifybind/", params);
  },
  // changeCode
  changePasswordApi: (params) => {
    return Post("/api/personal/password/modify/", params);
  },
  sendChangeCodeCodeApi: (params) => {
    return Post("/api/sms/send/resetpwd/", params);
  },
  changeCodeByPhoneCodeApi: (params) => {
    return Post("/api/personal/password/modifybyphone/", params);
  },
  // myCourse
  getMyCourseBaseDataApi: (params) => {
    return Post("/api/studycenter/", params);
  },
  getMyCourseListDataApi: (params) => {
    return Post("/api/studycenter/courselist/", params);
  },
  getLiveCourseListApi: (params) => {
    return Post("/api/studycenter/liveschedule/", params);
  },
  getCourseDetailApi: (params) => {
    return Post("/api/studycenter/coursedetail/", params);
  },
  // 增加 by 胡亚雷 20240807 获取课件
  getCourseWaresApi: (params) => {
    return Post("/api/studycenter/download_coursewares/", params);
  },
  getHomeWorksApi: (params) => {
    return Post("/api/studycenter/download_homeworks/", params);
  },
  onToStudyApi: (params) => {
    return Post("/api/studycenter/study/", params);
  },
  onUpdateProgressApi: (params) => {
    return Post("/api/studycenter/updateprogress/", params);
  },
  onToLiveRoomApi: (params) => {
    return Post("/api/studycenter/enterlive/", params);
  },
  // courseCenter
  getCourseCategoriesApi: (params) => {
    return Post("/api/product/categories/", params);
  },
  searchCourseGoodsListApi: (params) => {
    return Post("/api/product/search/", params);
  },
  getCourseGoodsListApi: (params) => {
    return Post("/api/course/list/", params);
  },
  getCourseGoodsDetailApi: (params) => {
    return Post("/api/product/detail/", params);
  },
  getCourseGoodsChaptersApi: (params) => {
    return Post("/api/course/chapters/", params);
  },
  getCourseGoodsRecommendApi: (params) => {
    return Post("/api/course/recommend/", params);
  },
  onAddShopCartApi: (params) => {
    return Post("/api/cart/add/", params);
  },
  onGetShopCartApi: (params) => {
    return Post("/api/cart/getcart/", params);
  },
  onEditCartGoodsNumApi: (params) => {
    return Post("/api/cart/editcart/", params);
  },
  onDelCartGoodsApi: (params) => {
    return Post("/api/cart/del/", params);
  },
  onCreateOrderApi: (params) => {
    return Post("/api/order/create/", params);
  },
  onGetOrderDetailApi: (params) => {
    return Post("/api/order/detail/", params);
  },
  onGetPayUrlApi: (params) => {
    return Post("/api/order/pay/", params);
  },
  onGetPayResultApi: (params) => {
    return Post("/api/order/orderquery/", params);
  },
  testPay: (params) => {
    return Get("/api/payment/callback_test/", params);
  },
  // my-order
  getOrderListApi: (params) => {
    return Post("/api/order/list/", params);
  },
  onCancleOrderApi: (params) => {
    return Post("/api/order/ordercancel/", params);
  },
  onDelOrderApi: (params) => {
    return Post("/api/order/orderdelete/", params);
  },
  // promotion
  getPromotionInfoApi: (params) => {
    return Post("/api/personal/promote/info/", params);
  },
  sendDepositCodeApi: (params) => {
    return Post("/api/sms/send/withdrawapply/", params);
  },
  onPromotionDepositApi: (params) => {
    return Post("/api/personal/promote/withdrawapply/", params);
  },
  getPromotionIncomeApi: (params) => {
    return Post("/api/personal/promote/query/", params);
  },
  // mentoring
  getMyMentoringApi: (params) => {
    return Post("/api/question/my/", params);
  },
  onPushQuestionApi: (params) => {
    return Post("/api/question/ask/", params);
  },
  onPutImgApi: (params) => {
    return Post("/api/personal/addimagebyte/", params);
  },
  //my-homework
  onGetHomeworkListApi: (params) => {
    return Post("/api/homework/list/", params);
  },
  onGetHomeworkRecordApi: (params) => {
    return Post("/api/homework/course/", params);
  },
  uploadHomeworkApi: (params) => {
    return Post("/api/homework/import/", params);
  },
  // coupon
  getCouponListApi: (params) => {
    return Post("/api/voucher/list/", params);
  },
  onGetShareCodeApi: (params) => {
    return Post("/api/qrcode/invitecode/", params);
  },
  // 获取签到状态
  getSignInStatusApi: (params) => {
    return Post("/api/activity/task/status/", params);
  },
  // h5预支付
  onGetPayParams: (params) => {
    return Post("/api/wx/prepay/", params);
  },
  // h5授权登录
  wxH5TryLoginApi: (params) => {
    return Post("/api/wxh5/trylogin/", params);
  },
  // h5jssdk签名
  wxH5SignApi: (params) => {
    return Post("/api/wxh5/urlsign/", params);
  },
  // 分销任务
  getAgentTaskListApi: (params) => {
    return Post("/api/agent/task/list/", params);
  },
  // 分销任务详情
  getAgentTaskDetailApi: (params) => {
    return Post("/api/agent/itemdetail/", params);
  },
  // 领取分销任务
  onGetAgentTaskApi: (params) => {
    return Post("/api/agent/task/get/", params);
  },
  // 下单分销任务
  onBuyAgentTaskApi: (params) => {
    return Post("/api/agent/item/createorder/", params);
  },
  // 成为新的分销
  getNewAgentIdApi: (params) => {
    return Post("/api/agent/item/newagent/", params);
  },
  // 获取分销收益等内容
  getAgentStatisticsApi: (params) => {
    return Post("/api/agent/statistics/", params);
  },
  // 分销余额提现
  onWithdrawMoneyApi: (params) => {
    return Post("/api/agent/withdrawal/", params);
  },
  // 分销报名
  onEnrollTaskActiveApi: (params) => {
    return Post("/api/agent/item/enroll/", params);
  },
  // 获取活动报名详情信息
  getActiveDetailInfoApi: (params) => {
    return Post("/api/enrollsheet/detail/", params);
  },
  // 验证邀请码是否正确
  checkInviteCodeApi: (params) => {
    return Post("/api/agent/findcode/", params);
  },
  // 埋点上报
  pushTrackBehaviorApi(params) {
    return Post("/api/behavior/upload/", params);
  },
};
