import Vue from "vue";
import VueRouter from "vue-router";
import Element from "element-ui";
import store from "../store";
import { isLogin as _isLogin } from "../utils/auth";

const Frame = () => import("../components/frame.vue");
const Home = () => import("../views/home/home.vue");
const ForgetCode = () => import("../views/home/forgetCode.vue");
const Register = () => import("../views/home/register.vue");
const UserAgreement = () => import("../views/home/userAgreement.vue");
const PrivacyPolicy = () => import("../views/home/privacyPolicy.vue");
const BannerIntroPage = () => import("../views/home/bannerIntroPage.vue");
const ActivePage = () => import("../views/home/activePage.vue");

const StudyCenter = () => import("../views/studyCenter");
const MyCourse = () => import("../views/studyCenter/myCourse.vue");
const MyClass = () => import("../views/studyCenter/myClass.vue");
const MyOrder = () => import("../views/studyCenter/myOrder.vue");
const Account = () => import("../views/studyCenter/account.vue");
const ChangeCode = () => import("../views/studyCenter/changeCode.vue");
const MyCoupons = () => import("../views/studyCenter/myCoupons.vue");
const MyInfo = () => import("../views/studyCenter/myInfo.vue");
const Promotion = () => import("../views/studyCenter/promotion.vue");
const MyExam = () => import("../views/studyCenter/myExam.vue");
const MyHomework = () => import("../views/studyCenter/myHomework.vue");
const HomeworkRecord = () => import("../views/studyCenter/homeworkRecord.vue");
const MyMentoring = () => import("../views/studyCenter/myMentoring.vue");

const LiveRoom = () => import("../views/studyCenter/liveRoom.vue");
const LiveRoomA = () => import("../views/studyCenter/liveRoom-a.vue");

const CourseCenter = () => import("../views/courseCenter/index.vue");
const CourseCenterHome = () => import("../views/courseCenter/home.vue");
const CourseDetail = () => import("../views/courseCenter/courseDetail.vue");
const CourseDetail2 = () => import("../views/courseCenter/courseDetail2.vue");
const ShopCart = () => import("../views/courseCenter/shopCart.vue");
const ConfirmOrder = () => import("../views/courseCenter/confirmOrder.vue");
const PayOrder = () => import("../views/courseCenter/payOrder.vue");
const PayPage = () => import("../views/courseCenter/payPage.vue");
const PayResult = () => import("../views/courseCenter/payResult.vue");
const PlayRoom = () => import("../views/courseCenter/playRoom.vue");

const H5Login = () => import("../views/h5/login.vue");
const H5ProductDetail = () => import("../views/h5/product-detail.vue");
const H5ConfirmOrder = () => import("../views/h5/confirm-order.vue");
const H5PayOrder = () => import("../views/h5/pay-order.vue");
const H5PayResult = () => import("../views/h5/pay-result.vue");

const H5AgentHome = () => import("../views/h5/agent/home.vue");
const H5AgentTaskList = () => import("../views/h5/agent/task-list.vue");
const H5AgentTaskDetail = () => import("../views/h5/agent/task-detail.vue");

const H5PluginsHome = () => import("../views/h5/plugins/home.vue");
const H5PluginsCenter = () => import("../views/h5/plugins/center.vue");

Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/forget-code",
    name: "forgetCode",
    component: ForgetCode,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/user-agreement",
    name: "userAgreement",
    component: UserAgreement,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/active-page",
    name: "activePage",
    component: ActivePage,
  },
  {
    path: "/",
    redirect: "/frame",
  },
  {
    path: "/frame",
    name: "frame",
    redirect: "/home",
    component: Frame,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/banner-page",
        name: "bannerPage",
        component: BannerIntroPage,
      },
      {
        path: "/live-room",
        name: "liveRoom",
        meta: { needLogin: true },
        component: LiveRoom,
      },
      {
        path: "/live-room-a",
        name: "liveRoomA",
        meta: { needLogin: true },
        component: LiveRoomA,
      },
      {
        path: "/course-center",
        name: "courseCenter",
        redirect: "/course-center/home",
        component: CourseCenter,
        children: [
          {
            path: "/course-center/home",
            name: "home",
            component: CourseCenterHome,
          },
          {
            path: "/course-center/detail",
            name: "courseDetail",
            component: CourseDetail,
          },
          {
            path: "/course-center/detail2",
            name: "courseDetail2",
            component: CourseDetail2,
          },
          {
            path: "/course-center/shop-cart",
            name: "shopCart",
            component: ShopCart,
          },
          {
            path: "/course-center/confirm-order",
            name: "confirmOrder",
            component: ConfirmOrder,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/pay-order",
            name: "payOrder",
            component: PayOrder,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/pay-page",
            name: "payPage",
            component: PayPage,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/pay-result",
            name: "payResult",
            component: PayResult,
            meta: { needLogin: true },
          },
          {
            path: "/course-center/play-room",
            name: "playRoom",
            component: PlayRoom,
            meta: { needLogin: true },
          },
        ],
      },
      {
        path: "/study-center",
        name: "studyCenter",
        redirect: "/study-center/my-course",
        component: StudyCenter,
        meta: { needLogin: true },
        children: [
          {
            path: "/study-center/my-course",
            name: "myCourse",
            meta: { needLogin: true },
            component: MyCourse,
          },
          {
            path: "/study-center/my-class",
            name: "myClass",
            meta: { needLogin: true },
            component: MyClass,
          },
          {
            path: "/study-center/my-order",
            name: "myOrder",
            meta: { needLogin: true },
            component: MyOrder,
          },
          {
            path: "/study-center/account",
            name: "account",
            meta: { needLogin: true },
            component: Account,
          },
          {
            path: "/study-center/change-code",
            name: "changeCode",
            meta: { needLogin: true },
            component: ChangeCode,
          },
          {
            path: "/study-center/my-coupons",
            name: "myCoupons",
            meta: { needLogin: true },
            component: MyCoupons,
          },
          {
            path: "/study-center/my-info",
            name: "myInfo",
            meta: { needLogin: true },
            component: MyInfo,
          },
          {
            path: "/study-center/promotion",
            name: "promotion",
            meta: { needLogin: true },
            component: Promotion,
          },
          {
            path: "/study-center/my-exam",
            name: "myExam",
            meta: { needLogin: true },
            component: MyExam,
          },
          {
            path: "/study-center/my-homework",
            name: "myHomework",
            meta: { needLogin: true },
            component: MyHomework,
          },
          {
            path: "/study-center/homework-record",
            name: "homeworkRecord",
            meta: { needLogin: true },
            component: HomeworkRecord,
          },
          {
            path: "/study-center/my-mentoring",
            name: "myMentoring",
            meta: { needLogin: true },
            component: MyMentoring,
          },
        ],
      },
    ],
  },
  {
    path: "/h5-page/login",
    name: "H5Login",
    component: H5Login,
  },
  {
    path: "/h5-page/course-detail",
    name: "H5ProductDetail",
    component: H5ProductDetail,
  },
  {
    path: "/h5-page/confirm-order",
    name: "H5ConfirmOrder",
    component: H5ConfirmOrder,
  },
  {
    path: "/h5-page/pay-order/pay-order",
    name: "H5PayOrder",
    component: H5PayOrder,
  },
  {
    path: "/h5-page/pay-result",
    name: "H5PayResult",
    component: H5PayResult,
  },
  {
    path: "/h5-page/agent/home",
    name: "H5AgentHome",
    component: H5AgentHome,
  },
  {
    path: "/h5-page/agent/task-list",
    name: "H5AgentTaskList",
    component: H5AgentTaskList,
  },
  {
    path: "/h5-page/agent/task-detail",
    name: "H5AgentTaskDetail",
    component: H5AgentTaskDetail,
  },
  {
    path: "/h5-page/plugins/home",
    name: "H5PluginsHome",
    component: H5PluginsHome,
  },
  {
    path: "/h5-page/plugins/center",
    name: "H5PluginsCenter",
    component: H5PluginsCenter,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const _frame = document.getElementsByClassName("frame-wrp")[0];
  _frame && _frame.scrollTo(0, 0);
  const path = to.path || "";
  const isLogin = _isLogin();
  if (!isLogin && to.meta.needLogin) {
    Element.Message.warning("请先登录账号再访问");
    return next("/");
  }
  if (to.path.includes("/study-center")) {
    store.commit("changePath", path.slice(path.lastIndexOf("/") + 1));
  }
  next();
});

export default router;
