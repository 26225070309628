import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import route from './route'
import course from "./course";
import track from "./track";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    route,
    course,
    track,
  },
});
