import axios from "axios";
import { getToken } from "../utils/auth";
import Element from "element-ui";
import store from "../store/index";
import { clearToken, clearUserInfo } from "../utils/auth";
import RESMSG from "./message";
import { coursePatch } from "../utils/patch.js";


let baseURL = "";
let qrCodeEnv = "release";

const host = location.host;
const env = host.includes("192.168.2.28")
  ? "dev"
  : host.includes("192.168.2.178")
  ? "trial"
  : "online";

console.log("env----", host,env);

switch (env) {
  case "dev":
    baseURL = "/dev-proxy";
    qrCodeEnv = "trial";
    break;
  case "trial":
    baseURL = "";
    qrCodeEnv = "trial";
    break;
  case "online":
    baseURL = "";
    qrCodeEnv = "release";
    break;
}
// 临时 by 胡亚雷 20240805，当需要在本机调试，并连到地址178时，取消下面的注释，
// baseURL = "/dev-proxy";
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    const token = getToken() || "";
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.accesstoken = token; // header 里添加 accesstoken
    }
    const vedioProgressInfo = store.state.course.videoProgressInfo;
    if (
      config.url !== "/api/studycenter/updateprogress/" &&
      vedioProgressInfo &&
      vedioProgressInfo.length
    ) {
      let now = new Date().getTime();
      if (
        store.state.course.videoUpdateTime &&
        now - store.state.course.videoUpdateTime < 10000
      )
        return config;
      store.commit("updateVideoTime", {});
      Post("/api/studycenter/updateprogress/", {
        viewed: vedioProgressInfo,
      }).then((res) => {
        store.commit("updateVideoProgressInfo", res.result.progress || []);
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.isSuccess) {
        if (response.request.responseURL.includes("/api/product/search/")) {
          response.data = coursePatch(response.data);
        }
        return response;
      } else {
        if (RESMSG[response.data.code]) {
          response.data.message = RESMSG[response.data.code];
          if (response.data.code === "E0006") {
            clearToken();
            clearUserInfo();
            store.commit("checkIsLogin");
          }
        }

        Element.Message.error(response.data.message);
        return Promise.reject(response.data);
      }
    } else {
      Element.Message.error(response.data.message);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
function Get(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装post方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
function Post(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装put方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
function Put(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装patch方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
function Patch(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 *封装delete方法
 *@param{String} url [请求地址]
 *@param{Object} params [请求参数]
 */
function Delete(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { Get, Post, Put, Patch, Delete, baseURL, qrCodeEnv };
