import { createUuid } from "./tools";
import { getUserInfo } from "../utils/auth";
import { trackTypeObj } from "../utils/options";
import Request from "../api/axios";

let _uuid = createUuid();
let _trackDataList = [];

const userAgent = navigator.userAgent.toLowerCase();
const _isIOS = /iphone|ipad|ipod/.test(userAgent);

const setUuid = (uuid) => {
  _uuid = uuid;
};

const _pushTrackData = (data) => {
  const now = new Date().getTime();
  _trackDataList.push({
    behaviorId: trackTypeObj[data.behavior],
    params: {
      eventType: data.type,
      ...data.params,
    },
    time: now,
  });
};

const onTrack = (data) => {
  _pushTrackData(data);
  const _userInfo = getUserInfo();
  if (_trackDataList.length >= 5) {
    Request.pushTrackBehaviorApi({
      device: _isIOS ? 1 : 2,
      uuid: _uuid,
      accountId:
        (_userInfo && _userInfo.id) ||
        undefined,
      behaviors: _trackDataList,
    }).then(() => {
      _trackDataList = [];
    });
  }
};

export { setUuid, onTrack };
