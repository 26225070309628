import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import axios from "axios";
import wxSdk from "weixin-js-sdk";
import "weui";
import weui from "weui.js";
import Request from "./api/axios";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style/index.css";

// const { RoomEngine, EventNameEnum } = window.RoomPaasSdk;
// const roomEngine = RoomEngine.getInstance();

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$wx = wxSdk;
Vue.prototype.$weui = weui;
Vue.prototype.$request = Request;
// Vue.prototype.$roomEngine = roomEngine;
// Vue.prototype.$EventNameEnum = EventNameEnum;
Vue.use(Element);
const vm = new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
